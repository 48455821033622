import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormItem, Radio } from '@patient-access/ui-kit';
import { updateForm } from 'actions/form';
import locale from 'service/locale';

type Props = {
  isAbleToEdit: boolean,
  organisationDetails: Organisation,
  form: Object,
  updateForm: (data: any) => Action,
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data)),
});

class SmartProfileVisibility extends Component<Props> {
  renderActionButton = () => {
    const { form, isAbleToEdit } = this.props;
    if (isAbleToEdit) {
      return (
        <button className="patient-care-btn-link" onClick={this.handleEditMode}>
          {form.isEditSmartProfileVisibility ? locale.OrganisationsSettings.organisationDetails.buttonDone : locale.OrganisationsSettings.organisationDetails.buttonEdit}
        </button>
      );
    }
    return null;
  }

  handleEditMode = (e: any) => {
    e && e.preventDefault();
    const { updateForm, form } = this.props;
    updateForm({ isEditSmartProfileVisibility: !form.isEditSmartProfileVisibility, isEditCompanyLogo: false });
  };

  handleFormChange = (val) => {
    const { updateForm } = this.props;
    updateForm({ isDataChanged: true, organisation_are_all_smartprofiles_visible: val });
  };

  render(){
    const { organisationDetails, form } = this.props;

    const feature = organisationDetails.supportedFeatures &&
    organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy')
    if (!(feature && feature.isEnabled)) return null;

    const { isEditSmartProfileVisibility } = form;
    const { areAllSmartProfilesVisible } = organisationDetails;
    const radioValue = form.organisation_are_all_smartprofiles_visible !== undefined ? form.organisation_are_all_smartprofiles_visible : areAllSmartProfilesVisible;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.smartPharmacySection.header}</h3>
            {this.renderActionButton()}
          </div>
          <p>{locale.OrganisationsSettings.smartPharmacySection.description}</p>
          <div className="patient-care-description-row-sp">
            <Form>
              <FormItem type="item">
                <Radio
                  id="id_hidden"
                  name="organisation_are_all_smartprofiles_visible"
                  value= "hide"
                  label={locale.OrganisationsSettings.smartPharmacySection.labelHidden}
                  disabled={!isEditSmartProfileVisibility}
                  onClick={(e) => { this.handleFormChange(false); }}
                  checked={(radioValue !== null && radioValue !== undefined && radioValue === false)} />
                <Radio
                  id="id_visible"
                  name="organisation_are_all_smartprofiles_visible"
                  value="visible"
                  label={locale.OrganisationsSettings.smartPharmacySection.labelVisible}
                  disabled={!isEditSmartProfileVisibility}
                  onClick={(e) => { this.handleFormChange(true); }}
                  checked={(radioValue !== null && radioValue !== undefined && radioValue === true)} />
              </FormItem>
            </Form>
          </div>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartProfileVisibility);
