import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Form, FormItem, InputLabel, Input, InputError, Textarea } from "@patient-access/ui-kit";

import { updateForm } from 'actions/form';
import { closeAddBranchNameError } from 'actions/panel';
import { checkEmail, checkPhoneNumber, checkDescription, validateDescription } from 'helpers/checkValues';
import AddBranchSearch from '.././AddBranchSearch/AddBranchSearch';
import * as RolesConstants from 'constants/RolesConstants';

import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
  handleSelectBranch: (branch: Branch) => Action,
  currentRole: object,
  organisationId?: string,
  isActiveAddBranchNameError: boolean,
  closeAddBranchNameError: () => Action,
};

type State = {
  name: string,
  description: string,
  odsCode: string,
  phone: string,
  email: string,
};

const mapStateToProps = state => ({
  form: state.form,
  currentRole: state.roles.profileCurrentRole,
  isActiveAddBranchNameError: state.panel.isActiveAddBranchNameError,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
  closeAddBranchNameError: () => dispatch(closeAddBranchNameError()),
});

class AddBranchInfo extends Component<Props, State> {

  state = {
    name: "",
    description: "",
    odsCode: "",
    phone: "",
    email: "",
  };

  componentWillUnmount = () => {
    this.setState({
      name: "",
      description: "",
      odsCode: "",
      phone: "",
      email: "",
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.form) {
      const { name, description, odsCode, phone, email } = nextProps.form;
      this.setState({
        name,
        description,
        odsCode,
        phone,
        email,
      });
    }
  };

  handleInputChange = (e: Event) => {
    const { updateForm, isActiveAddBranchNameError, closeAddBranchNameError } = this.props;
    const {
      target: { name, value }
    } = e;

    isActiveAddBranchNameError && closeAddBranchNameError();
    if (/^[+\-=@]/.test(value.trim())) {
      this.setState({ name: "" });
    }
    else {
      updateForm({ [name]: value, })
    }
  };

  handleChange = (value) => {
    const { handleSelectBranch, isActiveAddBranchNameError, closeAddBranchNameError } = this.props;
    isActiveAddBranchNameError && closeAddBranchNameError();
    handleSelectBranch(value);
  };

  render() {
    const { form, organisationId, currentRole, isActiveAddBranchNameError } = this.props;
    const { name, description, odsCode, phone, email } = this.state;
    const isPhoneCorrect = (phone || "").length === 0 || checkPhoneNumber(phone);
    const isEmailCorrect = (email || "").length === 0 || checkEmail(email);
    const isDescriptionCorrect = (description || "").length === 0 || checkDescription(description);
    const isEditedName = Object.keys(form).includes('name');
    const isEditedDescription = Object.keys(form).includes('description');
    const isEditedPhone = Object.keys(form).includes('phone');
    const isEditedEmail = Object.keys(form).includes('email');
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{`${organisationId && isSuperAdmin ? "2. " : "3. "}${locale.AddBranch.informationTitle}`}</h3>
          <p>{locale.AddBranch.informationText}</p>
        </div>
        <AddBranchSearch handleSelectBranch={this.handleChange} organisationId={organisationId}/> 
        <hr className="patient-care-separator" />
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item" error={((!name && isEditedName) || (name && isActiveAddBranchNameError)) ? 'true' : ''}>
              <InputLabel
                htmlFor="branch_name"
                message={locale.AddBranch.infoNameLabel}
                size="small"
              />
              <Input
                type="text"
                id="branch_name"
                name="name"
                data-id="branch_name"
                value={name || ""}
                onChange={this.handleInputChange}
                placeholder={locale.AddBranch.infoNamePlaceholder}
              />
              {
                !name && isEditedName &&
                <InputError message={locale.AddBranch.nameError} />
              }
              {
                name && isActiveAddBranchNameError &&
                <InputError message={locale.AddBranch.duplicateNameError} />
              }
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item" error={(!isDescriptionCorrect && isEditedDescription) ? 'true' : ''}>
              <InputLabel
                htmlFor="branch_description"
                message={locale.AddBranch.infoDescriptionLabel}
                size="small"
              />
              <Textarea
                id="branch_description"
                name="description"
                data-id="branch_description"
                value={description || ""}
                onChange={this.handleInputChange}
                maxLength={500}
                countValue={description ? description.length : 0}
                countMax={500}
                placeholder={locale.AddBranch.infoDescriptionPlaceholder}
              />
              {
                !isDescriptionCorrect && isEditedDescription &&
                <div className="input-error">
                  <p>{locale.AddBranch.descriptionError} {validateDescription(description)}</p>
                </div>
              }
            </FormItem>
          </Form>
        </div>
        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item">
                <InputLabel
                  htmlFor="branch_code"
                  message={locale.AddBranch.infoOdsLabel}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_code"
                  name="odsCode"
                  data-id="branch_code"
                  value={odsCode || ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.infoOdsPlaceholder}
                />
              </FormItem>
            </Form>
          </div>
        </div>

        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item" error={(!isPhoneCorrect && isEditedPhone) ? 'true' : ''}>
                <InputLabel
                  htmlFor="branch_telephone"
                  message={locale.AddBranch.infoTelephoneLabel}
                  size="small"
                />
                <Input
                  type="text"
                  id="branch_telephone"
                  name="phone"
                  data-id="branch_telephone"
                  value={phone || ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.infoTelephonePlaceholder}
                />
                {
                  !isPhoneCorrect && isEditedPhone &&
                  <InputError message={locale.AddBranch.phoneError} />
                }
              </FormItem>
            </Form>
          </div>
        </div>

        <div className="patient-care-form-block">
          <div className="patient-care-col-6">
            <Form>
              <FormItem type="item" error={(!isEmailCorrect && isEditedEmail) ? 'true' : ''}>
                <InputLabel
                  htmlFor="branch_email"
                  message={locale.AddBranch.infoEmailLabel}
                  size="small"
                />
                <Input
                  type="email"
                  id="branch_email"
                  name="email"
                  data-id="branch_email"
                  value={email || ""}
                  onChange={this.handleInputChange}
                  placeholder={locale.AddBranch.infoEmailPlaceholder}
                />
                {
                  !isEmailCorrect && isEditedEmail &&
                  <InputError message={locale.AddBranch.emailError} />
                }
              </FormItem>
            </Form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchInfo);
