import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormItem, Input, InputError } from "@patient-access/ui-kit";

import { updateForm } from 'actions/form';
import locale from 'service/locale';

type Props = {
  form: any,
  updateForm: (date: any) => any,
};

type State = {
  name: string
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateForm: (data: any) => dispatch(updateForm(data)),
});

class AddSoleTraderOrganisationName extends Component<Props, State>
{
  state = {
    name: ""
  };

  componentWillUnmount = () => {
    this.setState({
      name: ""
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.form) {
      const { name } = nextProps.form;
      this.setState({
        name
      });
    }
  };

  handleInputChange = (e: Event) => {
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;

    if (/^[+\-=@]/.test(value.trim())) {
      this.setState({ name: "" });
    }
    else {
      updateForm({ [name]: value, })
    }
  };

  render() {
    const { form } = this.props;
    const { name } = this.state;
    const isEditedName = Object.keys(form).includes('name');

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <h3>{locale.AddOrganisation.companyInfo.nameTitle}</h3>
        </div>
        <div className="patient-care-form-block">
          <Form>
            <FormItem type="item" error={(!name && isEditedName) ? 'true' : ''}>
              <Input
                type="text"
                id="organisation_name"
                name="name"
                data-id="organisation_name"
                value={name || ""}
                onChange={this.handleInputChange}
              />
              {
                !name && isEditedName &&
                <InputError message={locale.AddOrganisation.companyInfo.nameError} />
              }
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSoleTraderOrganisationName);