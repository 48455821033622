import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  Input,
  InputLabel,
  InputError
} from "@patient-access/ui-kit";

import locale from "service/locale";

import { updateForm } from "actions/form";

// import "./styles.scss";

type Props = {
  isAbleToEdit: boolean,
  organisationDetails: Organisation,

  form: Object,
  updateForm: (data: any) => Action,
};

const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
   updateForm: data => dispatch(updateForm(data)),
});

class CompanyName extends Component<Props, State> {

  renderReadOnlyMode = () => {
    const { organisationDetails } = this.props;
    const { form } = this.props;

    return (
      <Fragment>
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="organisation_name"
                  message={locale.OrganisationsSettings.companyName}
                  size="small"
                />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {
                form.organisation_name || organisationDetails.name
              }
            </span>
          </div>
        </div>
        {organisationDetails.registrationNumber && (
          <div className="patient-care-block">
            <div className="patient-care-col-6">
              <Form noValidate>
                <FormItem type="item">
                  <InputLabel
                    htmlFor="organisation_code"
                    message={locale.OrganisationsSettings.companyNumber}
                    size="small"
                  />
                </FormItem>
              </Form>
              <span className="patient-care-filled-input">
                {organisationDetails.registrationNumber}
              </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  renderEditMode = () => {
    const { organisationDetails } = this.props;
    const {
      form,
    } = this.props;

    const isEmptyOrganisationName = (form.organisation_name && !form.organisation_name.length) === "";

    return (
      <Fragment>
      <div className="patient-care-block">
        <div className="patient-care-col-6">
          <Form noValidate>
            <FormItem
              type="item"
              error={isEmptyOrganisationName ? "error" : null}
            >
              <InputLabel
                htmlFor="organisation_name"
                message={locale.OrganisationsSettings.companyName}
                size="small"
              />
              <Input
                type="text"
                id="organisation_name"
                name="organisation_name"
                defaultValue={ form.organisation_name || organisationDetails.name}
                onChange={this.handleFormChange}
              />
              {isEmptyOrganisationName && (
                <InputError
                  message={
                    locale.OrganisationsSettings.organisationNameError
                  }
                />
              )}
            </FormItem>
          </Form>
        </div>
      </div>
      {organisationDetails.registrationNumber && (
        <div className="patient-care-block">
          <div className="patient-care-col-6">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel
                  htmlFor="organisation_code"
                  message={locale.OrganisationsSettings.companyNumber}
                  size="small"
                />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {organisationDetails.registrationNumber}
            </span>
          </div>
        </div>
      )}
    </Fragment>
    );
  }

  renderActionButton = () => {
    const { form, isAbleToEdit, organisationDetails } = this.props;
    if (isAbleToEdit && !organisationDetails.registrationNumber) {
      return (
        <button
          className="patient-care-btn-link"
          onClick={this.handleCompanyDetailsEditMode}
        >
          {form.isEditCompanyNameMode
            ? locale.OrganisationsSettings.organisationDetails
              .buttonDone
            : locale.OrganisationsSettings.organisationDetails
              .buttonEdit}
        </button>
      );
    }

    return null;
  }

  handleCompanyDetailsEditMode = (e: any) => {
    e && e.preventDefault();
    const { form, organisationDetails, updateForm } = this.props;

    if (form.isEditCompanyNameMode) {
      const { organisation_name } = form;
      const { name } = organisationDetails;

      const isNameEntered =
        organisation_name === undefined
          ? name.length > 0
          : organisation_name.length > 0;

      if (isNameEntered) {
        updateForm({ isEditCompanyNameMode: false });
      }
    } else {
      updateForm({ isEditCompanyNameMode: true });
    }
  };

  handleFormChange = (e: any) => {   
    const { updateForm } = this.props;
    const {
      target: { name, value }
    } = e;
    if (/^[+\-=@]/.test(value.trim())) {
      updateForm({ isDataChanged: true, [name]: "" })
    }
    else {
      updateForm({ isDataChanged: true, [name]: value });
    }
  };

  render() {
    const { isEditCompanyNameMode } = this.props.form;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.details}</h3>
            {this.renderActionButton()}
          </div>
          <p>{locale.OrganisationsSettings.detailsDescription}</p>
        </div>
        {isEditCompanyNameMode ? this.renderEditMode() : this.renderReadOnlyMode()}
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyName);