import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { createSelector } from 'reselect';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Skeleton, Form, FormItem, Checkbox } from "@patient-access/ui-kit";
import IconDragVerical from 'svg/IconDragVerical';
import { ButtonRemove } from 'components/Share/button';
import locale from "service/locale";
import { updateForm } from "actions/form";
import { getEnabledServiceDetails } from "actions/enabledServices";
import "./styles.scss";

const getServices = state => state.enabledServices.servicesLoadedInitially;

export const getEnabledOnlyServices = createSelector(
  [getServices],
  services => {
    const enabledServices = services && services.filter(s => s.enabled);
    return enabledServices && enabledServices.map((service) => {
      service.value = service.id;
      service.serviceId = service.id;
      service.label = service.name;
      return service;
    });
  },
);

type Props = {
  isAbleToEdit: boolean,
  organisationDetails: Organisation,
  organisationId: string,
  form: Object,
  updateForm: (data: any) => Action,
  servicesForFeatured: any[],
  isServicesPending: boolean,
  getEnabledServiceDetails: (organisationId: string) => Action,
};

const mapStateToProps = (state) => ({
  form: state.form,
  servicesForFeatured: getEnabledOnlyServices(state),
  isServicesPending: state.enabledServices.isServicesPending,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  updateForm: data => dispatch(updateForm(data)),
  getEnabledServiceDetails: (organisationId: string) =>
    dispatch(getEnabledServiceDetails((organisationId: string))),
});

const DragHandle = SortableHandle(() => {
  return (
    <i className="move">
      <IconDragVerical outline={false} />
    </i>
  );
});

const SortableItem = SortableElement(({ children }) => <Fragment>{children}</Fragment>);

const SortableList = SortableContainer(({ children }) => <Fragment>{children}</Fragment>);

class FeaturedServices extends Component<Props, State> {

  componentDidMount = () => {
    const { getEnabledServiceDetails, organisationId } = this.props;
    getEnabledServiceDetails(organisationId);
  };

  handleFormChange = (e: any) => {
    const { updateForm } = this.props;
    const { target: { name, value } } = e;
    updateForm({ isDataChanged: true, [name]: value });
  };

  handleCheckboxupdate = (e) => {
    const isChecked = e && e.target && e.target.checked;
    var event = { target: { name: 'organisation_featuredServices_BranchControl', value: isChecked } };
    this.handleFormChange(event);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { form, organisationDetails } = this.props;
    let services = form.organisation_featuredServices === undefined ? (organisationDetails.featuredService && organisationDetails.featuredService.services) : form.organisation_featuredServices;
    services = arrayMove(services, oldIndex, newIndex);
    this.handleChange(services);
  };

  handleAddFeaturedService = (e) => {
    e.preventDefault();
    const { form, organisationDetails } = this.props;
    const services = (form.organisation_featuredServices === undefined ? (organisationDetails.featuredService && organisationDetails.featuredService.services) : form.organisation_featuredServices) || [];
    services.push({ name: null, id: null });
    this.handleChange(services);
  };

  handleChange = (value) => {
    var e = { target: { name: 'organisation_featuredServices', value: value } };
    this.handleFormChange(e);
  }

  handleDropDownChange = (e, index) => {
    const { servicesForFeatured, form, organisationDetails } = this.props;
    const services = form.organisation_featuredServices === undefined ? (organisationDetails.featuredService && organisationDetails.featuredService.services) : form.organisation_featuredServices;
    const service = servicesForFeatured.find(option => option.serviceId === e.value);
    if (service) {
      services[index] = service;
      this.handleChange(services);
    }
  };

  handleRemoveButtonClick = (e, index) => {
    e.preventDefault();
    const { form, organisationDetails } = this.props;
    const services = form.organisation_featuredServices === undefined ? (organisationDetails.featuredService && organisationDetails.featuredService.services) : form.organisation_featuredServices;
    services.splice(index, 1);
    this.handleChange(services);
  };

  getDropDownList = () => {
    const { servicesForFeatured, form, organisationDetails } = this.props;
    const selectedServices = form.organisation_featuredServices || (organisationDetails.featuredService && organisationDetails.featuredService.services);
    const selectedIds = selectedServices && selectedServices.map(s => s.serviceId);

    if (selectedIds && selectedIds.length > 0) {
      return servicesForFeatured && servicesForFeatured.filter(x => !selectedIds.includes(x.serviceId));
    }
    return servicesForFeatured;
  }

  renderSortableService = (service, index) => {
    const { servicesForFeatured } = this.props;

    return (
      <div key={`item-${index}`} className="patient-care-sortable-item">
        <DragHandle />
        <div className="index">{index + 1}.</div>
        <div className="value">
          <Select
            id="some_id"
            name="organisation_featuredServices_BranchControl"
            placeholder="Select a service to feature"
            defaultValue={servicesForFeatured && servicesForFeatured.find(option => option.serviceId === service.serviceId)}
            options={this.getDropDownList()}
            classNamePrefix="patient-care"
            onChange={(e) => this.handleDropDownChange(e, index)}
            className="patient-care-select"
          />
        </div>
        <ButtonRemove onClick={(e) => this.handleRemoveButtonClick(e, index)} />
      </div>
    );
  };

  renderServices = (services) => {
    return (
      <div className="patient-care-sortable-wrapper">
        {services && services.map((service, index) => {
          return (
            <SortableItem key={`item-${index}-${service.serviceId}`} index={index}>
              {this.renderSortableService(service, index)}
            </SortableItem>
          )
        })}
      </div>
    );
  };

  renderAddButton = () => {
    const { servicesForFeatured, form, organisationDetails } = this.props;
    const selectedServices = form.organisation_featuredServices || (organisationDetails.featuredService && organisationDetails.featuredService.services);
    const servicesCount = (servicesForFeatured && servicesForFeatured.length) || 0;
    const selectedServicesCount = (selectedServices && selectedServices.length) || 0;

    if (servicesCount > selectedServicesCount) {
      return (
        <div className="patient-care-featured-services">
          <Link to="#add" onClick={(e) => { e.preventDefault(); this.handleAddFeaturedService(e); }}>Add a featured service</Link>
        </div>
      );
    }
    return null;
  }

  renderEditMode = (services) => {
    return (
      <Fragment>
        <SortableList onSortEnd={this.onSortEnd} lockAxis="y" useDragHandle>
          {this.renderServices(services)}
        </SortableList>
        {this.renderAddButton()}
      </Fragment>
    );
  };

  renderReadMode = (services) => {
    const { servicesForFeatured } = this.props;
    if (!(servicesForFeatured && servicesForFeatured.length > 0)) {
      return (
        <div className="patient-care-featured-services">
          <p className="info">{locale.OrganisationsSettings.organisationFeaturedServices.noServices}</p>
        </div>
      );
    }

    if (services && services.length > 0) {
      return (
        <div className="patient-care-featured-services">
          {services.map((service, index) => (
            <p key={index}>{index + 1}. {service.name}</p>
          ))}
        </div>
      );
    }

    return (
      <div className="patient-care-featured-services">
        <p className="info">{locale.OrganisationsSettings.organisationFeaturedServices.noFeaturedServices}</p>
      </div>
    );
  };

  handleFeaturedServicesEditMode = (e: any) => {
    e && e.preventDefault();
    this.props.updateForm({ isEditFeaturedServices: !this.props.form.isEditFeaturedServices, isEditCompanyLogo: false });
  };

  renderFeaturedServicesActions = (isAbleToEdit) => {
    const { buttonDone, buttonEdit } = locale.OrganisationsSettings.organisationDetails;

    if (!isAbleToEdit) return null;
    return (
      <button className="patient-care-btn-link" onClick={this.handleFeaturedServicesEditMode} >
        {this.props.form.isEditFeaturedServices ? buttonDone : buttonEdit}
      </button>
    );
  };

  render() {
    const { form, isAbleToEdit, organisationDetails } = this.props;
    const { isEditFeaturedServices } = form;
    const keys = Object.keys(form);
    const feature = organisationDetails.supportedFeatures && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy')
    const services = keys.includes("organisation_featuredServices") ? form.organisation_featuredServices : (organisationDetails.featuredService && organisationDetails.featuredService.services);
    const isBranchControlFeaturedService = keys.includes("organisation_featuredServices_BranchControl") ? form.organisation_featuredServices_BranchControl : (organisationDetails.featuredService && organisationDetails.featuredService.isBranchControlFeaturedService);

    if (!(feature && feature.isEnabled)) return null;
    if (this.props.isServicesPending) return <Skeleton type="basic" />;

    return (
      <Fragment>
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.OrganisationsSettings.organisationFeaturedServices.title}</h3>
            {this.renderFeaturedServicesActions(isAbleToEdit)}
          </div>
          <p>{locale.OrganisationsSettings.organisationFeaturedServices.description}</p>
          {isEditFeaturedServices ? this.renderEditMode(services) : this.renderReadMode(services)}
          <Form>
            <FormItem type="item">
              <Checkbox id="organisation_featuredServices_BranchControl"
                name="organisation_featuredServices_BranchControl"
                value="organisation_featuredServices_BranchControl"
                label={locale.OrganisationsSettings.organisationFeaturedServices.allowBranchControlText}
                checked={isBranchControlFeaturedService}
                onChange={this.handleCheckboxupdate}
                disabled={!isEditFeaturedServices}
                data-id="organisation_featuredServices_BranchControl"
                className=""
              />
            </FormItem>
          </Form>
        </div>
        <hr className="patient-care-separator" />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(FeaturedServices);
