import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import find from "lodash.find";
import { Form, FormItem, Input, InputLabel, Textarea, Button, InputError, Overlay, Skeleton } from "@patient-access/ui-kit";

import { validateEditBranchInfo, validateEditBranchAddress } from "helpers/validateData";
import { checkEmail, checkPostcode, checkPhoneNumber, checkDescription, validateDescription } from "helpers/checkValues";

import { updateForm, clearForm } from "actions/form";
import { deleteBranch, removeRedirect, getFutureAppointmentsForBranch } from "actions/branches";
import { setBranchConfirmationStatus, getBranchDetails, updateBranch, closeBranchErrorDuplicateOds } from "actions/branchDetails";
import { setBranchHeader } from "actions/router";

import type { Action } from "types/actions";
import type { ConfirmationStatus } from "constants/BranchesConstants";

import * as RolesConstants from "constants/RolesConstants";
import * as BranchesConstants from "constants/BranchesConstants";
import * as RoutesConstants from 'constants/RoutesConstants';
import * as OrganisationsConstants from "constants/OrganisationsConstants";

import FeaturedServices from "./FeaturedServices/FeaturedServices";
import ConfirmationOverlay from "components/Share/ConfirmationOverlayCommon/ConfirmationOverlayCommon";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";

import locale from "service/locale";

type Props = {
  branchId: string,
  organisationId: string,
  updateForm: (data: any) => Action,
  clearForm: () => Action,
  getFutureAppointmentsForBranch: (branchId: string) => any,
  deleteBranch: (branchId: string, password: string) => any,
  updateBranch: (branchId: string, branchData: Object) => any,
  setBranchConfirmationStatus: (status: ConfirmationStatus) => Action,
  getBranchDetails: (organisationId: string, branchId: string) => any,
  currentRole: Object,
  form: Object,
  confirmationStatus: ConfirmationStatus,
  isActiveAgenda: boolean,
  branchDetails: Object,
  branches: any[],
  removeRedirect: () => Action,
  setBranchHeader: (branchId: string) => any,
  isUpdateBranchSettingsPending: boolean,
  isBranchErrorDuplicatedOds: boolean,
  branchErrorDuplicatedOdsMessage: string,
  closeBranchErrorDuplicateOds: () => Action,
};

type State = {
  branch: Object,
  isEditNameAndNumberMode: boolean,
  isEditAddressMode: boolean,
  isEditSmartProfileMode: boolean,
  isEditModeFeaturedServices: boolean,
  branch_name: string,
  branch_description: string,
  branch_code: string,
  branch_email: string,
  branch_address_1: string,
  branch_address_2: string,
  branch_postcode: string,
  branch_city: string,
  isConfirmationError: boolean,
  isEmptyBranchAddress: boolean,
  isRedirect: {
    status: boolean,
    redirectUrl: string
  },
  openDiscardChanges: boolean,
  isSmartProfileOverlayVisible: boolean,
};

const mapStateToProps = state => ({
  branches: state.branches.branchesList,
  branchDetails: state.branchDetails,
  currentRole: state.roles.profileCurrentRole,
  confirmationStatus: state.branchDetails.confirmationStatus,
  form: state.form,
  isActiveAgenda: state.panel.isActiveAgenda,
  isUpdateBranchSettingsPending: state.branchDetails.isUpdateBranchSettingsPending,
  isBranchErrorDuplicatedOds: state.panel.isBranchErrorDuplicatedOds,
  branchErrorDuplicatedOdsMessage: state.panel.branchErrorDuplicatedOdsMessage,
  isVirtual: state.branchDetails.isVirtual,
  orgSupportedFeatures: state.organisationDetails.supportedFeatures
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: data => dispatch(updateForm(data)),
  clearForm: () => dispatch(clearForm()),
  getFutureAppointmentsForBranch: (branchId) => dispatch(getFutureAppointmentsForBranch(branchId)),
  deleteBranch: (branchId, password) => dispatch(deleteBranch(branchId, password)),
  updateBranch: (branchId, branchData) => dispatch(updateBranch(branchId, branchData)),
  setBranchConfirmationStatus: status => dispatch(setBranchConfirmationStatus(status)),
  getBranchDetails: (organisationId, branchId) => dispatch(getBranchDetails(organisationId, branchId)),
  removeRedirect: () => dispatch(removeRedirect()),
  setBranchHeader: (branchId) => dispatch(setBranchHeader(branchId)),
  closeBranchErrorDuplicateOds: () => dispatch(closeBranchErrorDuplicateOds()),
});

class BranchDetails extends Component<Props, State> {
  state = {
    branch: {},
    isEditNameAndNumberMode: false,
    isEditAddressMode: false,
    isEditDescriptionMode: false,
    isEditODSCodeMode: false,
    isEditSmartProfileMode: false,
    isEditModeFeaturedServices: false,
    branch_name: "",
    branch_description: "",
    branch_code: "",
    branch_phone: "",
    branch_email: "",
    branch_address_1: "",
    branch_address_2: "",
    branch_postcode: "",
    branch_city: "",
    branch_enhanced_profile: false,
    isSmartProfileOverlayVisible: false,
    isConfirmationError: false,
    isEmptyBranchAddress: false,
    isRedirect: {
      status: false,
      redirectUrl: ""
    },
    openDiscardChanges: false,
  }

  componentDidMount = () => {
    const { getBranchDetails, branchId, setBranchConfirmationStatus, setBranchHeader, organisationId } = this.props;
    setBranchHeader(branchId);
    setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING);
    getBranchDetails(organisationId, branchId);
  }

  componentWillUnmount = () => {
    const { removeRedirect, clearForm } = this.props;
    removeRedirect();
    clearForm();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { confirmationStatus, organisationId, branchDetails, branchId } = nextProps;

    if (branchDetails && !this.props.isUpdateBranchSettingsPending) {
      this.setState({ branch: branchDetails });
    }

    switch (confirmationStatus) {
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING:
        this.setState({ isConfirmationError: false });
        return;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CONFIRMED:
        this.setState({
          isRedirect: {
            status: true,
            redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${RoutesConstants.BRANCHES}`
          }
        },
          this.clearConfirmationStatus
        );
        return;
      case BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.UPDATED:
        this.setState({
          isRedirect: {
            status: true,
            redirectUrl: `${RoutesConstants.ADMIN_ORGANISATIONS}/${organisationId}/${branchId}/${RoutesConstants.DETAILS}`
          }
        },
          this.clearConfirmationStatus
        );
        return;
      default:
        break;
    }
    this.setState({ isConfirmationError: true });
  }

  clearConfirmationStatus = () => {
    const { setBranchConfirmationStatus } = this.props;
    setBranchConfirmationStatus(BranchesConstants.PASSWORD_CONFIRMATION_STATUSES.CHECKING);
  }

  handleSelectCompanyEditMode = (e: any) => {
    e && e.preventDefault();
    const { isEditNameAndNumberMode, branch } = this.state;
    const { form } = this.props;
    const keys = Object.keys(form);

    if (isEditNameAndNumberMode) {
      const { branch_name, branch_phone, branch_email } = form;
      const { name, phone, email } = branch;
      const isEnteredBranchName = branch_name === undefined ? name.length > 0 : branch_name.length > 0;
      const isEditedName = keys.includes("branch_name");
      const isEditedPhone = keys.includes("branch_phone");
      const isEditedEmail = keys.includes("branch_email");
      if (
        !validateEditBranchInfo({
          name: isEditedName ? branch_name : name,
          phone: isEditedPhone ? branch_phone : phone,
          email: isEditedEmail ? branch_email : email
        })
      ) {
        return;
      }

      if (isEnteredBranchName) {
        this.setState({
          isEditNameAndNumberMode: false,
          branch: {
            ...branch,
            name: branch_name || name,
            phone: branch_phone || phone,
            email: branch_email || email
          }
        });
      }
    } else {
      this.setState({ isEditNameAndNumberMode: true });
    }
  }

  handleSelectAddressEditMode = (e: any) => {
    e && e.preventDefault();
    const { isEditAddressMode, branch } = this.state;
    const { form, isVirtual } = this.props;
    const keys = Object.keys(form);

    if (isEditAddressMode) {
      const { branch_address_1, branch_address_2, branch_city, branch_postcode } = form;
      const { address } = branch;
      const { addressLine1, addressLine2, postcode, town } = address;
      const isEnteredBranchAddress = branch_address_1 === undefined ? addressLine1.length > 0 : branch_address_1.length > 0;
      const isEditedAddressLine1 = keys.includes("branch_address_1");
      const isEditedPostcode = keys.includes("branch_postcode");
      const isEditedTown = keys.includes("branch_city");

      if (isVirtual) {
        this.setState({ isEditAddressMode: false });
      }

      if (
        !validateEditBranchAddress({
          addressLine1: isEditedAddressLine1 ? branch_address_1 : addressLine1,
          postcode: isEditedPostcode ? branch_postcode : postcode,
          town: isEditedTown ? branch_city : town
        })
      )
        return;
      if (isEnteredBranchAddress) {
        this.setState({
          isEditAddressMode: false,
          branch: {
            ...branch,
            address: {
              addressLine1: branch_address_1 || addressLine1,
              addressLine2: branch_address_2 || addressLine2,
              town: branch_city || town,
              postcode: branch_postcode || postcode
            }
          }
        });
      }
    } else {
      this.setState({ isEditAddressMode: true });
    }
  }

  handleSelectDescriptionEditMode = (e: any) => {
    e && e.preventDefault();
    const { isEditDescriptionMode, branch } = this.state;
    const { form } = this.props;

    if (isEditDescriptionMode) {
      const { branch_description } = form;
      const { description } = branch;
      this.setState({
        isEditDescriptionMode: false,
        branch: {
          ...branch,
          description: branch_description || description,
        }
      });
    }
    else {
      this.setState({ isEditDescriptionMode: true });
    }
  }

  handleSelectODSCodeEditMode = (e: any) => {
    e && e.preventDefault();
    const { isEditODSCodeMode, branch } = this.state;
    const { form } = this.props;

    if (isEditODSCodeMode) {
      const { branch_code } = form;
      const { odsCode } = branch;
      this.setState({
        isEditODSCodeMode: false,
        branch: {
          ...branch,
          odsCode: branch_code || odsCode,
        }
      });
    } else {
      this.setState({ isEditODSCodeMode: true });
    }
  }

  handleSmartProfileEditMode = (e: any) => {
    e && e.preventDefault();
    const { isEditSmartProfileMode } = this.state;
    if (isEditSmartProfileMode) {
      this.setState({ isEditSmartProfileMode: false });
    } else {
      this.setState({ isEditSmartProfileMode: true });
    }
  }

  handleFeaturedServicesEditMode = (e: any) => {
    const { isEditModeFeaturedServices } = this.state;
    e && e.preventDefault();
    this.setState({ isEditModeFeaturedServices: !isEditModeFeaturedServices });
  }

  handleBranchVisibilityChange = (isEnabled) => {
    const { updateForm } = this.props;
    updateForm({ isDataChanged: true, branch_enhanced_profile: isEnabled });

    this.setState({
      branch_enhanced_profile: isEnabled,
    });
  }

  handleFormChange = (e: any) => {
    const { updateForm } = this.props;
    const { target: { name, value } } = e;
    updateForm({ isDataChanged: true, [name]: value });
    this.setState({ [name]: value });
  }

  handleFormNameChange = (e: any) => {
    const { updateForm } = this.props;
    const { target: { name, value } } = e;
    if (/^[+\-=@]/.test(value.trim())) {
      updateForm({ isDataChanged: true, [name]: "" });
      this.setState({ [name]: "" });
    }
    else {
      updateForm({ isDataChanged: true, [name]: value });
      this.setState({ [name]: value });
    }
  }

  handleFocus = (e: any) => {
    const { target: { name, value } } = e;
    this.setState({ [name]: value });
  }

  handleBlur = (e: any) => {
    const { target: { name, value } } = e;
    this.setState({ [name]: value });
  }

  handleDiscardChanges = (e: any) => {
    e && e.preventDefault();
    this.setState({ openDiscardChanges: true });
  }

  handleUpdateBranch = (e: any) => {
    e && e.preventDefault();
    const { updateBranch, branchId } = this.props;
    const { branch } = this.state;
    updateBranch(branchId, branch);
  }

  handleUpdateBranchWithCondition = (e: any) => {
    const { branch_enhanced_profile, branch_description } = this.state;

    if (!this.state.branch.description && !branch_description && branch_enhanced_profile) {
      this.setState({ isSmartProfileOverlayVisible: true });
    } else {
      this.handleUpdateBranch(e);
    }
  }

  handleConfirm = () => {
    const { clearForm, branches, branchId, branchDetails, getBranchDetails, organisationId } = this.props;
    this.setState(
      {
        isEditAddressMode: false,
        isEditNameAndNumberMode: false,
        isEditODSCodeMode: false,
        isEditDescriptionMode: false,
        isEditSmartProfileMode: false,
        isEditModeFeaturedServices: false,
        branch: find(branches, { id: branchId }) || branchDetails,
        openDiscardChanges: false
      });
    clearForm();
    getBranchDetails(organisationId, branchId);
  }

  handleCancel = () => {
    this.setState({ openDiscardChanges: false });
  }

  handleCloseInformationOverlay = () => {
    const { closeBranchErrorDuplicateOds } = this.props;
    closeBranchErrorDuplicateOds();
  }

  render() {
    const { branch, isEditNameAndNumberMode, isEditAddressMode, isRedirect, branch_address_1, branch_city, branch_name, branch_description, branch_phone, branch_email, branch_postcode, openDiscardChanges, isEditDescriptionMode, isEditODSCodeMode, isEditSmartProfileMode, isSmartProfileOverlayVisible, isEditModeFeaturedServices } = this.state;
    const { currentRole, isActiveAgenda, form, isUpdateBranchSettingsPending, isBranchErrorDuplicatedOds, branchErrorDuplicatedOdsMessage, isVirtual, branchDetails, organisationId } = this.props;

    const isAbleToEdit = currentRole.role !== RolesConstants.BRANCH_MEMBER;
    const isEmailCorrect = (form.branch_email || branch_email).length === 0 || checkEmail(form.branch_email || branch_email);
    const isPostcodeCorrect = isVirtual ? true : checkPostcode(branch_postcode || (branch.address && branch.address.postcode));
    const isEmptyPostcode = isVirtual ? false : (form.branch_postcode && !form.branch_postcode.length) === "" && !branch_postcode;
    const isPhoneCorrect = (branch.phone || branch_phone).length === 0 || checkPhoneNumber(branch_phone || branch.phone);
    const isEmptyBranchAddress = isVirtual ? false : (form.branch_address_1 && !form.branch_address_1.length) === "" && !branch_address_1;
    const isEmptyBranchCity = isVirtual ? false : (form.branch_city && !form.branch_city.length) === "" && !branch_city;
    let   isEmptyBranchName = (form.branch_name && !form.branch_name.length) === "" && !branch_name;
    const isEmptyPhoneNumber = (form.branch_phone && !form.branch_phone.length) === "" && !branch_phone;
    const isEmptyEmail = (form.branch_email && !form.branch_email.length) === "" && !branch_email;
    const isEmptyDescription = (form.branch_description && !form.branch_description.length) === "" && !branch_description;
    const isEmptyODSCode = (form.branch_code && !form.branch_code.length) === "";
    const isEmptyBranchAddress2 = (form.branch_address_2 && !form.branch_address_2.length) === "";
    const isDescriptionCorrect = (branch.description || branch_description).length === 0 || checkDescription(branch_description || branch.description);
    const isFormValid = !isEmptyBranchName && !isEmptyBranchAddress && !isEmptyBranchCity && isEmailCorrect && isPostcodeCorrect && !isEmptyPostcode && isPhoneCorrect && isDescriptionCorrect;
    const keys = Object.keys(form);
    isEmptyBranchName = form.branch_name === '' ? true : false;
    const branchSmartPharmacy = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeatures.find((feature) => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS);
    const isBranchSmartPharmacy = branchSmartPharmacy && branchSmartPharmacy.isEnabled;

    const renderBranchDetails = () => {
      if (isEditNameAndNumberMode) {
        return (
          <Fragment>
            <div className="patient-care-block">
              <Form noValidate>
                <FormItem type="item" error={isEmptyBranchName ? "error" : null} >
                  <InputLabel htmlFor="branch_name" message={locale.BranchSettings.companyName} size="small" />
                  <Input
                    type="text"
                    id="branch_name"
                    name="branch_name"
                    data-id="branch_name"
                    defaultValue={branch.name}
                    onChange={this.handleFormNameChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {isEmptyBranchName ? <InputError message={locale.BranchSettings.emptyBranchName} /> : null}
                </FormItem>
              </Form>
            </div>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item" error={isPhoneCorrect ? null : "true"}>
                    <InputLabel htmlFor="branch_phone" message={locale.BranchSettings.phone} size="small" />
                    <Input
                      type="text"
                      id="branch_phone"
                      name="branch_phone"
                      data-id="branch_phone"
                      defaultValue={keys.includes("branch_phone") ? form.branch_phone : branch.phone}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      placeholder={locale.BranchSettings.phonePlaceHolder}
                    />
                    {!isPhoneCorrect ? <InputError message={locale.AddBranch.phoneError} /> : null}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item" error={!isEmailCorrect ? "error" : null}>
                    <InputLabel htmlFor="branch_email" message={locale.BranchSettings.email} size="small" />
                    <Input
                      type="text"
                      id="branch_email"
                      name="branch_email"
                      data-id="branch_email"
                      defaultValue={keys.includes("branch_email") ? form.branch_email : branch.email}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      placeholder={locale.BranchSettings.emailPlaceHolder}
                    />
                    {!isEmailCorrect ? <InputError message={locale.BranchSettings.incorrectBranchEmail} /> : null}
                  </FormItem>
                </Form>
              </div>
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_name" message={locale.BranchSettings.companyName} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">{branch.name}</span>
          </div>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_phone" message={locale.BranchSettings.phone} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyPhoneNumber || !branch.phone ? locale.BranchSettings.notProvided : keys.includes("branch_phone") ? form.branch_phone : branch.phone}
            </span>
          </div>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_email" message={locale.BranchSettings.email} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyEmail || !branch.email ? locale.BranchSettings.notProvided : keys.includes("branch_email") ? form.branch_email : branch.email}
            </span>
          </div>
        </Fragment>
      );
    };

    const renderBranchDescription = () => {
      if (isEditDescriptionMode) {
        return (
          <Fragment>
            <div className="patient-care-block">
              <Form noValidate>
                <FormItem type="item">
                  <Textarea
                    id="branch_description"
                    name="branch_description"
                    data-id="branch_description"
                    defaultValue={keys.includes("branch_description") ? form.branch_description : branch.description}
                    onChange={this.handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    maxLength={500}
                    countValue={branch_description.length}
                    countMax={500}
                    placeholder={locale.BranchSettings.descriptionPlaceHolder}
                  />
                  {!isDescriptionCorrect ? <div className="input-error"><p>{locale.AddBranch.descriptionError} {validateDescription(branch_description)}</p></div> : null}
                </FormItem>
              </Form>
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <div className="patient-care-block">
            <span className="patient-care-filled-input patient-care-filled-input-full">
              {isEmptyDescription || !branch.description
                ? locale.BranchSettings.descriptionNotProvided
                : keys.includes("branch_description")
                  ? form.branch_description
                  : branch.description
              }
            </span>
          </div>
        </Fragment>
      );
    };

    const renderBranchAddress = () => {
      if (isEditAddressMode) {
        return (
          <Fragment>
            <div className="patient-care-block">
              <Form noValidate>
                <FormItem type="item" error={isEmptyBranchAddress ? "error" : null} >
                  <InputLabel htmlFor="branch_address_1" message={locale.OrganisationsSettings.addressLine1} size="small" />
                  <Input
                    type="text"
                    id="branch_address_1"
                    name="branch_address_1"
                    data-id="branch_address_1"
                    defaultValue={keys.includes("branch_address_1") ? form.branch_address_1 : branch.address.addressLine1}
                    onChange={this.handleFormChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  {isEmptyBranchAddress ? <InputError message={locale.BranchSettings.emptyBranchAddress} /> : null}
                </FormItem>
              </Form>
            </div>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel htmlFor="branch_address_2" message={locale.OrganisationsSettings.addressLine2} size="small" />
                    <Input
                      type="text"
                      id="branch_address_2"
                      name="branch_address_2"
                      data-id="branch_address_2"
                      defaultValue={keys.includes("branch_address_2") ? form.branch_address_2 : branch.address.addressLine2}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    />
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item" error={isEmptyBranchCity ? "error" : null}>
                    <InputLabel htmlFor="branch_city" message={locale.OrganisationsSettings.city} size="small" />
                    <Input
                      type="text"
                      id="branch_city"
                      name="branch_city"
                      data-id="branch_city"
                      defaultValue={keys.includes("branch_city") ? form.branch_city : branch.address.town}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    />
                    {isEmptyBranchCity ? <InputError message={locale.BranchSettings.townError} /> : null}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item" error={(!isPostcodeCorrect || isEmptyPostcode) ? "error" : null}>
                    <InputLabel htmlFor="branch_postcode" message={locale.OrganisationsSettings.postcode} size="small" />
                    <Input
                      type="text"
                      id="branch_postcode"
                      name="branch_postcode"
                      data-id="branch_postcode"
                      defaultValue={keys.includes("branch_postcode") ? form.branch_postcode : branch.address.postcode}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    />
                    {(!isPostcodeCorrect || isEmptyPostcode) ? <InputError message={locale.BranchSettings.postcodeError} /> : null}
                  </FormItem>
                </Form>
              </div>
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_address_1" message={locale.OrganisationsSettings.addressLine1} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyBranchAddress || !(branch.address && branch.address.addressLine1) || (isVirtual && (form.branch_address_1 && !form.branch_address_1.length) === "")
                ? locale.BranchSettings.notProvided
                : keys.includes("branch_address_1")
                  ? form.branch_address_1
                  : branch.address && branch.address.addressLine1}
            </span>
          </div>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_address_2" message={locale.OrganisationsSettings.addressLine2} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyBranchAddress2 || !(branch.address && branch.address.addressLine2) ? locale.BranchSettings.notProvided : keys.includes("branch_address_2") ? form.branch_address_2 : branch.address && branch.address.addressLine2}
            </span>
          </div>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_city" message={locale.OrganisationsSettings.city} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyBranchCity || !(branch.address && branch.address.town) || (isVirtual && (form.branch_city && !form.branch_city.length) === "")
                ? locale.BranchSettings.notProvided
                : keys.includes("branch_city")
                  ? form.branch_city
                  : branch.address && branch.address.town}
            </span>
          </div>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_postcode" message={locale.OrganisationsSettings.postcode} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyPostcode || !(branch.address && branch.address.postcode) || (isVirtual && (form.branch_postcode && !form.branch_postcode.length) === "")
                ? locale.BranchSettings.notProvided
                : keys.includes("branch_postcode")
                  ? form.branch_postcode
                  : branch.address && branch.address.postcode}
            </span>
          </div>
        </Fragment>
      );
    };

    const renderBranchODSCode = () => {
      if (isEditODSCodeMode) {
        return (
          <Fragment>
            <div className="patient-care-block">
              <div className="patient-care-col-6">
                <Form noValidate>
                  <FormItem type="item">
                    <InputLabel htmlFor="branch_code" message={locale.BranchSettings.ODSCode} size="small" />
                    <Input
                      type="text"
                      id="branch_code"
                      name="branch_code"
                      data-id="branch_code"
                      defaultValue={keys.includes("branch_code") ? form.branch_code : branch.odsCode}
                      onChange={this.handleFormChange}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    />
                  </FormItem>
                </Form>
              </div>
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <div className="patient-care-block">
            <Form noValidate>
              <FormItem type="item">
                <InputLabel htmlFor="branch_code" message={locale.BranchSettings.ODSCode} size="small" />
              </FormItem>
            </Form>
            <span className="patient-care-filled-input">
              {isEmptyODSCode || !branch.odsCode
                ? locale.BranchSettings.notProvided
                : keys.includes("branch_code")
                  ? form.branch_code
                  : branch.odsCode
              }
            </span>
          </div>
        </Fragment>
      );
    };

    const renderBranchSmartProfile = () => {
      if (!isBranchSmartPharmacy) {
        return null;
      }

      const { supportedFeatures } = branchDetails;
      const featureSmartProfile = supportedFeatures.find(feature => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_ENHANCEDPROFILE);

      const isEnabled = keys.includes("branch_enhanced_profile") ? form.branch_enhanced_profile : (featureSmartProfile && featureSmartProfile.isEnabled);
      const visibleClass = isEnabled ? 'active' : '';
      const invisibleClass = isEnabled ? '' : 'active';

      return (
        <Fragment>
          <hr className="patient-care-separator" />
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.BranchSettings.smartProfileHeader}</h3>
              {isAbleToEdit ? <button className="patient-care-btn-link" onClick={this.handleSmartProfileEditMode}>{isEditSmartProfileMode ? locale.Appointment.customerDetails.buttonDone : locale.Appointment.customerDetails.buttonEdit}</button> : null}
            </div>
            <p>{locale.BranchSettings.smartProfileCopy}</p>
          </div>
          <div className="patient-care-block">
            <ul className={`patient-care-unstyled-list patient-care-status-list ${isEditSmartProfileMode ? '' : 'patient-care-status-list-disabled'}`}>
              <li onClick={() => { this.handleBranchVisibilityChange(false); }} className={`patient-care-status-item ${invisibleClass}`}>Invisible</li>
              <li onClick={() => { this.handleBranchVisibilityChange(true); }} className={`patient-care-status-item ${visibleClass}`}>Visible</li>
            </ul>
          </div>
        </Fragment>
      );
    }

    const renderLogo = (img) => {
      if (img) {
        return <div className="logo-wrap">
          <img src={img} alt="" id="company-logo" className="company-logo" />
        </div>;
      }

      return <div className="logo-wrap">
        <span className="no-image-message">No logo uploaded</span>
      </div>;
    };

    const renderCompanyLogo = () => {
      if (!isBranchSmartPharmacy) {
        return null;
      }

      const companyLogo = branchDetails.logo || "";
      return <Fragment>
        <hr className="patient-care-separator" />
        <div className="patient-care-description-block">
          <div className="patient-care-heading-row">
            <h3>{locale.BranchSettings.companyLogoHeader}</h3>
          </div>
          <p>{locale.BranchSettings.companyLogoDescription}</p>
        </div>
        {renderLogo(companyLogo)}
      </Fragment>;
    };

    const renderFeaturedServices = () => {
      if (!isBranchSmartPharmacy) {
        return null;
      }

      return (
        <FeaturedServices
          isAbleToEdit={isAbleToEdit}
          isEditMode={isEditModeFeaturedServices}
          organisationId={organisationId}
          handleFeaturedServicesEditMode={this.handleFeaturedServicesEditMode}
        />
      );
    };

    const renderFooterButtons = () => {
      if (form.isDataChanged) {
        return (
          <div className={`patient-care-buttons-fixed-bottom ${isActiveAgenda ? "is-active-agenda" : ""}`}>
            <div className="patient-care-container-sm">
              <div className="patient-care-row-align-right">
                <Button
                  buttonType="blueline"
                  messageKey="discard-btn"
                  defaultMessage={locale.Appointment.buttons.discardChanges}
                  onClick={this.handleDiscardChanges}
                  isLoading={isUpdateBranchSettingsPending}
                  data-id="discard-btn"
                  className="patient-care-btn-in-group"
                />
                <Button
                  buttonType="secondary"
                  messageKey="save-changes-btn"
                  defaultMessage={locale.Appointment.buttons.saveChanges}
                  onClick={this.handleUpdateBranchWithCondition}
                  isLoading={isUpdateBranchSettingsPending}
                  data-id="save-changes-btn"
                  className="patient-care-btn-in-group"
                  isDisabled={!isFormValid}
                  tabIndex={!isFormValid ? -1 : 0}
                />
              </div>
            </div>
          </div>
        );
      }
      return null;
    };

    const renderConfirmationOverlay = () => {
      if (openDiscardChanges) {
        return (
          <ConfirmationOverlay
            handleConfirm={this.handleConfirm}
            handleCancel={this.handleCancel}
            content={locale.Modals.discardChangesOverlay.content}
            header={locale.Modals.discardChangesOverlay.header}
            buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
            buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
          />
        );
      }
      return null;
    };

    const renderInformationalOverlay = () => {
      if (isBranchErrorDuplicatedOds) {
        return (
          <InformationalOverlay
            header={locale.Modals.odsDuplicateModal.header}
            content={branchErrorDuplicatedOdsMessage}
            buttonOk={locale.Modals.odsDuplicateModal.button}
            handleOk={this.handleCloseInformationOverlay}
          />
        );
      }
      return null;
    };

    const renderSmartProfileOverlay = () => {
      return (
        <Overlay background="dark" type="confirmation" isOpen={isSmartProfileOverlayVisible} showCloseButton={false} doClose={() => { }}>
          <div className="overlay-confirmation-header">
            <h1>{locale.BranchSettings.smartProfileOverlay.header}</h1>
          </div>
          <div className="overlay-confirmation-content">
            <h4>{locale.BranchSettings.smartProfileOverlay.copy}</h4>
          </div>
          <div className="overlay-confirmation-buttons">
            <Button
              buttonType="blueline"
              messageKey="some-key"
              defaultMessage={locale.BranchSettings.smartProfileOverlay.buttonContinue}
              onClick={() => { this.setState({ isSmartProfileOverlayVisible: false }); this.handleUpdateBranch(); }}
            />
            <Button
              buttonType="secondary"
              messageKey="some-key"
              defaultMessage={locale.BranchSettings.smartProfileOverlay.buttonEditDescription}
              onClick={() => { this.setState({ isSmartProfileOverlayVisible: false, isEditDescriptionMode: true }); }}
            />
          </div>
        </Overlay>
      );
    };

    if (isRedirect.status) {
      return <Redirect to={isRedirect.redirectUrl} />;
    }

    if (branchDetails && branchDetails.isServicesPending) {
      return (
        <div className={form.isDataChanged ? "patient-care-details-holder data-changed" : "patient-care-details-holder"}>
          <div className="patient-care-container-sm">
            <div className="patient-care-description-block">
              <Skeleton type="basic" className="transparent" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={form.isDataChanged ? "patient-care-details-holder data-changed" : "patient-care-details-holder"}>
        <div className="patient-care-container-sm">
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.BranchSettings.details}</h3>
              {isAbleToEdit ? <button className="patient-care-btn-link" onClick={this.handleSelectCompanyEditMode}>{isEditNameAndNumberMode ? locale.Appointment.customerDetails.buttonDone : locale.Appointment.customerDetails.buttonEdit}</button> : null}
            </div>
            <p>{locale.BranchSettings.detailsDescription}</p>
          </div>
          {renderBranchDetails()}
          <hr className="patient-care-separator" />
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.BranchSettings.companyDescription}</h3>
              {isAbleToEdit ? <button className="patient-care-btn-link" onClick={this.handleSelectDescriptionEditMode}>{isEditDescriptionMode ? locale.Appointment.customerDetails.buttonDone : locale.Appointment.customerDetails.buttonEdit}</button> : null}
            </div>
            <p>{locale.BranchSettings.branchDescription}</p>
          </div>
          {renderBranchDescription()}
          <hr className="patient-care-separator" />
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.BranchSettings.registeredAddress}</h3>
              {isAbleToEdit ? <button className="patient-care-btn-link" onClick={this.handleSelectAddressEditMode}>{isEditAddressMode ? locale.Appointment.customerDetails.buttonDone : locale.Appointment.customerDetails.buttonEdit}</button> : null}
            </div>
            <p>{locale.BranchSettings.addressDescription}</p>
          </div>
          {renderBranchAddress()}
          <hr className="patient-care-separator" />
          <div className="patient-care-description-block">
            <div className="patient-care-heading-row">
              <h3>{locale.BranchSettings.branchODSCode}</h3>
              {isAbleToEdit ? <button className="patient-care-btn-link" onClick={this.handleSelectODSCodeEditMode}>{isEditODSCodeMode ? locale.Appointment.customerDetails.buttonDone : locale.Appointment.customerDetails.buttonEdit}</button> : null}
            </div>
          </div>
          {renderBranchODSCode()}
          {renderCompanyLogo()}
          {renderBranchSmartProfile()}
          {renderFeaturedServices()}
        </div>
        {renderFooterButtons()}
        {renderConfirmationOverlay()}
        {renderInformationalOverlay()}
        {renderSmartProfileOverlay()}
        <NavigationPrompt when={form.isDataChanged} >
          {({ onConfirm, onCancel }) => (
            <ConfirmationOverlay
              handleConfirm={onConfirm}
              handleCancel={onCancel}
              content={locale.Modals.discardChangesOverlay.content}
              header={locale.Modals.discardChangesOverlay.header}
              buttonConfirm={locale.Modals.discardChangesOverlay.buttonDiscard}
              buttonCancel={locale.Modals.discardChangesOverlay.buttonStay}
            />
          )}
        </NavigationPrompt>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetails);
